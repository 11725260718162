/**
 * Custom commons static methods here
 */

import { environment } from 'src/environments/environment';
import { Slider } from './models/Slider';
import { Feature } from './models/Feature';

export class CustomCommons {

    static readonly PATH_SERVICE_STATUS = 'status'

    static readonly DUMMY_SLIDERS: Slider[] = [
    {
      id: -1,
      background: 'assets/images/slideshow1-2.png',
            title: 'Un nuevo concepto para tu empresa',
            text_part_1: 'Sabes lo que',
            text_part_2: 'Softdirex puede hacer por ti?'
    },
    {
      id: -2,
            background: 'assets/images/slideshow1-1.png',
            title: 'CONSOLIDA TU EMPRESA',
            text_part_1: 'Y marca la diferencia',
            text_part_2: 'con sitios web profesionales'
    },
    {
      id: -3,
            background: 'assets/images/slideshow1-3.png',
            title: 'Sientete a gusto!',
            text_part_1: 'Obtén un sistema',
            text_part_2: 'a tu medida'
    }
  ]

  static readonly DUMMY_FEATURES: Feature[] = [
    {
      id: -1,
      image: 'assets/images/cat-1.png',
            title: 'Muestra tu Marca al Mundo',
            text: 'Nos comprometemos a ser tu aliado en la presentación de tu empresa ante el mundo, empleando tecnología de vanguardia y garantizando seguridad en cada paso.',
      route: null,
      routelabel: 'Shop now',
    },
    {
      id: -2,
      image: 'assets/images/cat-2.png',
            title: 'Gana Tiempo',
            text: '¡El tiempo es oro!, es por eso que ofrecemos soluciones instantáneas diseñadas para maximizar tu eficiencia y productividad.',
      route: '',
      routelabel: null,
    },
    {
      id: -3,
      image: 'assets/images/cat-3.png',
            title: 'Automatización',
            text: 'Potencia tu organización con la digitalización. A través de nuestras soluciones, transformamos tus procesos tradicionales en operaciones eficientes y ágiles.',
      route: '',
      routelabel: null,
    },
    {
      id: -4,
      image: 'assets/images/cat-4.png',
            title: 'tu Tech Partner!',
            text: 'Nuestro compromiso va más allá de simplemente proveer un producto o servicio. Nos esforzamos por ofrecer ayuda, capacitación y soporte inmediato.',
      route: '',
      routelabel: null,
    }
    ,
    {
      id: -5,
      image: 'assets/images/cat-5.png',
            title: 'Colaboración Activa en el Desarrollo',
            text: 'Nuestro proceso implica una estrecha colaboración con nuestros clientes, quienes tienen la oportunidad de revisar y aprobar cada fase de sus proyectos.',
      route: '',
      routelabel: null,
    }
    ,
    {
      id: -6,
      image: 'assets/images/cat-6.png',
            title: 'Optimiza la Gestión y Control de tus Datos',
            text: 'Ponemos a tu disposición herramientas que simplifican la administración de datos, permitiéndote centrarte en lo que realmente importa: el éxito de tu organización y la satisfacción de tus clientes.',
      route: '',
      routelabel: null,
    }
  ]

  static readonly DUMMY_PUBLIC_SERVICE = {
    id: -1,
    title: 'Características del servicio',
        content: 'Softdirex se encarga de que los sistemas de nuestros clientes sean rápidos, poco convencionales, atractivos y accesibles desde cualquier dispositivo que tenga acceso a internet.\n\nAgiliza todos los procesos de tu oficina y trabaja con mayor fluidez realizando todo el control en un software con planes a tu medida.\n\nTenemos información completa de nuestros servicios en donde podrás ver el detalle y sus características, podrás cotizar o solicitar alguna otra información que pueda ser de ayuda en su necesidad de implementar un sitio web, aplicación web, software u otros servicios detallados en nuestro portal. Gracias por visitarnos!.'
  }

  static readonly DUMMY_PUBLICATIONS = [
    {
      id: -1,
      type: null,
            background: 'assets/images/item-1.png',
            title: 'Desarrollo',
            introduction: 'Desarrollamos el proyecto que necesitas',
      btnLink: ''
    },
    {
      id: -2,
      type: null,
            background: 'assets/images/item-2.png',
            title: 'Flexibilidad',
            introduction: 'Ofrecemos planes de pago convenientes',
      btnLink: ''
    },
    {
      id: -3,
      type: null,
            background: 'assets/images/item-3.png',
      title: 'Soporte',
            introduction: 'Servicios de asesoría y soporte técnico',
      btnLink: ''
    }
  ]

  static readonly DUMMY_HOMELINK = {
    id: -1,
    background: '../../../../assets/images/counter.jpg',
    name: 'Softdirex',
    title: "Puedes encontrar más productos en Escritorio Empresas",
    text: '¡Apúrate! Registrate y conoce todas las características de nuestros productos. ¡Aprovecha ahora!',
    button_label: 'Store',
    access: environment.coreFrontendEndpoint,
  }
}