<div class="modal-backdrop" *ngIf="isOpen" (click)="closeChatbot()"></div>
<div class="modal" [ngClass]="{'open': isOpen}">
  <div class="modal-header mb-5">
    <div class="chatbot-icon-container">
      <img *ngIf="loading" class="chatbot-icon" src="../../../../../assets/img/gif/softy.gif">
      <img *ngIf="!loading" class="chatbot-icon" src="../../../../../assets/img/png/softy-icon.png">
    </div>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button class="btn btn-default btn-extra-small" (click)="closeChatbot()">
      <i class="tf-ion-android-close"></i>
    </button>
  </div>
  <div class="modal-body mt-5">
    <div class="chat-container">
      <div #chatWindow class="chat-window">
        <div *ngFor="let message of messages" class="message" [ngClass]="{'sent': message.sent, 'received': !message.sent}" [innerHTML]="message.text">
        </div>
        <div *ngIf="loading" class="col-12">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img class="loading-img align-items-center" src="../../../../../assets/img/gif/writing.gif">
        </div>
      </div>
      <div class="input-container">
        <input *ngIf="!loading" [(ngModel)]="userInput" (keyup.enter)="sendMessage()" placeholder="{{'label.type-message'|transloco}}."/>
        <button *ngIf="!loading" class="btn btn-extra-small" (click)="sendMessage()">
          <i class="tf-ion-android-send"></i>
        </button>
        <input *ngIf="loading" placeholder="{{'label.waiting-softy'|transloco}}" disabled="true"/>
        <button *ngIf="loading" class="btn btn-extra-small btn-danger">
          <i class="tf-ion-android-time"></i>
        </button>
      </div>
    </div>
  </div>
</div>
