import { AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { OpenaiService } from 'src/app/services/openai.service';
import { lastValueFrom } from 'rxjs';
import { Commons } from 'src/app/shared/Commons';
import { LanguageUtilService } from 'src/app/services/language-util.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrl: './chatbot.component.scss'
})
export class ChatbotComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatWindow') private chatWindow!: ElementRef;
  @Output() close = new EventEmitter<void>();
  userInput: string = '';
  messages: { text: string, sent: boolean }[] = [];
  isOpen = false;
  loading = false
  firstScroll = false

  constructor(
    private service: OpenaiService,
    private langService: LanguageUtilService,) { }

  ngOnInit(): void {
    let msgTrx = Commons.decryptDataLocal(localStorage.getItem('Oiamsg'))
    if (msgTrx) {
      this.messages = msgTrx
    } else {
      this.userInput = this.langService.translate('label.hi')
      this.sendMessage()
    }
  }

  ngAfterViewChecked() {
    if (!this.firstScroll) {
      this.scrollToBottom();
      this.firstScroll = true
    }
  }

  private scrollToBottom(): void {
    try {
      this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
    } catch (err) { }
  }

  async sendMessage() {
    if (this.userInput.trim()) {
      this.loading = true;
      const userMessage = { text: this.userInput, sent: true };
      if (this.messages.length > 0) {
        //No guarda el primer hola generado automaticamente
        this.messages.push(userMessage);
      }

      let thread_id = Commons.decryptDataLocal(localStorage.getItem('Othread'));
      const response = await lastValueFrom(this.service.sendMessage(userMessage.text, thread_id));

      let iaMsg = null;
      let msgContent = null;
      let maxRetries = 0;

      if (response && response.data && response.data[0] && response.data[0].thread_id) {
        thread_id = response.data[0].thread_id;
        localStorage.setItem('Othread', Commons.encryptDataLocal(thread_id));

        await this.delay(4000);

        do {
          iaMsg = await lastValueFrom(this.service.getMessages(thread_id));
          msgContent = this.getLastTextItem(iaMsg.data);
          if (!msgContent) {
            await this.delay(3000);
          }
          maxRetries++;
        } while (msgContent == null && maxRetries <= 3);

        if (msgContent != null) {
          const botMessage = { text: msgContent, sent: false };
          this.messages.push(botMessage);
        }
      }

      localStorage.setItem('Oiamsg', Commons.encryptDataLocal(this.messages));
      this.loading = false;
      this.userInput = '';
    }
  }

  getLastTextItem(data: any[]): any | null {
    let latestTimestamp: number | null = null;
    let lastText: any = null;

    for (const element of data) {
      if (element.role === 'assistant' && element.content) {
        if (latestTimestamp === null || element.created_at > latestTimestamp) {
          latestTimestamp = element.created_at;
          for (const item of element.content) {
            if (item.type === 'text' && item.text) {
              lastText = this.convertTextToHtml(item.text.value);
            }
          }
        }
      }
    }
    return lastText;
  }


  convertTextToHtml(text: string): string {
    let html = text.replace(/^###### (.*$)/gim, '<h6>$1</h6>')
      .replace(/^##### (.*$)/gim, '<h5>$1</h5>')
      .replace(/^#### (.*$)/gim, '<h4>$1</h4>')
      .replace(/^### (.*$)/gim, '<h3>$1</h3>')
      .replace(/^## (.*$)/gim, '<h2>$1</h2>')
      .replace(/^# (.*$)/gim, '<h1>$1</h1>');
    html = html.replace(/\*\*(.*?)\*\*/gim, '<strong>$1</strong>');
    html = html.replace(/\*(.*?)\*/gim, '<em>$1</em>');
    html = html.replace(/`(.*?)`/gim, '<code>$1</code>');
    html = html.replace(/\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/gim, '<a href="$2" target="_blank">$1</a>');
    html = html.replace(/!\[([^\]]*)\]\((https?:\/\/[^\s)]+)\)/gim, '<img src="$2" alt="$1" />');
    html = html.replace(/^\s*[-+*]\s+(.*$)/gim, '<ul><li>$1</li></ul>');
    html = html.replace(/^\s*[0-9]+\.\s+(.*$)/gim, '<ol><li>$1</li></ol>');
    html = html.replace(/\n/g, '<br>');
    html = html.replace(/<\/ul><br><ul>/gim, '')
      .replace(/<\/ol><br><ol>/gim, '');
    return html;
  }


  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  closeChatbot() {
    this.isOpen = false;
    this.close.emit();
  }

  openChatbot() {
    this.isOpen = true;
  }
}
