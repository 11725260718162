import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { OwnerConfig } from 'src/app/shared/interfaces/core/owner-config';
import { LanguageUtilService } from 'src/app/services/language-util.service';
import { SessionService } from 'src/app/services/session.service';
import { Commons } from 'src/app/shared/Commons';
import { CustomCommons } from 'src/app/shared/CustomCommons';
import { SelectLanguageModalComponent } from 'src/app/shared/modals/select-language-modal/select-language-modal.component';
import { CompaniesService } from 'src/app/services/companies.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkService } from 'src/app/services/cdk.service';

/*
Can BE:
  ...
  templateUrl: './custom/{CUSTOM_COMPONENT_NAME}.component.html'
  styleUrls: ['./custom/{CUSTOM_COMPONENT_NAME}.component.scss']
  ...
*/
@Component({
  selector: 'app-header',
  templateUrl: './custom/header.component.html',
  styleUrls: ['./custom/header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  ownerDetail: OwnerConfig = Commons.emptyOwnerConfig()

  PATH_ABOUT = '/' + Commons.PATH_ABOUT
  PATH_CONTACT = '/' + Commons.PATH_CONTACT
  PATH_MY_CUSTOMER = '/' + Commons.PATH_MY_CUSTOMER
  PATH_PRODUCT = '/' + Commons.PATH_PRODUCT
  PATH_LOGIN = '/' + Commons.PATH_LOGIN
  PATH_REGISTER = '/' + Commons.PATH_REGISTER
  PATH_PUBLICATIONS = '/' + Commons.PATH_PUBLICATIONS
  PATH_PRODUCT_SITE = environment.productSite
  withNavBarLogo = environment.withNavBarLogo
  webmailSite = environment.webmailSite
  enableWebmailSiteLink = environment.enableWebmailSiteLink
  getScreenWidth: any;
  mobileWidth: number = Commons.MOBILE_WIDTH
  sessionIsOpen: boolean = Commons.sessionIsOpen()
  sessionObject: any = Commons.sessionObject()
  selLangModal: MdbModalRef<SelectLanguageModalComponent> | null = null;
  isSticky = false;
  logoUrl: any
  DF_LOGO = environment.ownerId != 0 ? Commons.DF_COMPANY_LOGO : Commons.DF_PRODUCT_LOGO
  @ViewChild('mainNavbar', { static: true }) mainNavbar!: ElementRef;
  /* BEGIN | CUSTOM VARS */
  PATH_SERVICE_STATUS = '/' + CustomCommons.PATH_SERVICE_STATUS
  PATH_SERVICE_STATUS_ADMIN = environment.checkservices + '/admin/login/?next=/admin/'
  PATH_STORE = environment.coreFrontendEndpoint
  PATH_EE_REGISTER = environment.coreFrontendEndpoint + '/register'
  /* END | CUSTOM VARS */

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private service: CompaniesService,
    private modalService: MdbModalService,
    private langService: LanguageUtilService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private sanitizer: DomSanitizer,
    private cdkService: CdkService,
    /* BEGIN | CUSTOM ARGS */
    /* END | CUSTOM ARGS */
  ) { }

  ngOnInit(): void {
    /* BEGIN | CUSTOM CODE */
    /* END | CUSTOM CODE */
    this.loadOwnerConfig()
    this.getScreenWidth = window.innerWidth
    window.addEventListener('scroll', this.onWindowScroll);
    this.loadLogo()
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  onWindowScroll = () => {
    const scrollOffset = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
    this.isSticky = scrollOffset > 0;
  }

  ngAfterViewInit(){
    const newIconClass = 'tf-ion-ios-arrow-up'
    const oldIconClass = 'tf-ion-ios-arrow-down'
    const elements = document.querySelectorAll('.nav-link');
    const effects = ['vibrate-effect','zoom-effect','splash-effect','fade-effect','rotate-effect']
    const effect = effects[1]
    elements.forEach((element) => {
      element.addEventListener('mousemove', () => {
        if(element.className === 'nav-link dropdown-toggle ml-5'){
          const icon = element.querySelector('i');
          if (icon && new RegExp("\\b" + oldIconClass + "\\b", "i").test(icon.className)) {
            icon.classList.remove(oldIconClass);
            icon.classList.add(newIconClass);
          }
        }
        element.classList.add(effect);
      });

      element.addEventListener('mouseout', () => {
        if(element.className.startsWith('nav-link dropdown-toggle ml-5')){
          const icon = element.querySelector('i');
          if (icon && new RegExp("\\b" + newIconClass + "\\b", "i").test(icon.className)) {
            icon.classList.remove(newIconClass);
            icon.classList.add(oldIconClass);
          }
        }
        element.classList.remove(effect);
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth
  }

  loadOwnerConfig() {
    this.route.queryParams.subscribe(params => {
      const subdomain = params['subdomain']
      const validated = Commons.getOwner()
      const ownerIdentifier = (environment.ownerId != 0) ? environment.ownerId : subdomain
      if (validated == null) {
        //load from endpoint
        if(ownerIdentifier){
          this.service.getOwner(ownerIdentifier).subscribe(
            {
              next: async (v) => {
                const owner = await this.cdkService.getTrxDec(v.trx)
                this.ownerDetail = owner.config
                Commons.setOwner(owner)
                window.location.reload();
              },
              error: () => {
                this.ownerDetail = Commons.getDefaultConfig()
              },
              complete: () => { }
            }
          )
        }
      } else {
        this.ownerDetail = validated.config
      }
    })
  }

  closeSession() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      Commons.sessionClose()
      this.router.navigate([Commons.PATH_MAIN]).then(() => {
        window.location.reload();
      });
    }
  }

  openLanguageModal() {
    this.selLangModal = this.modalService.open(SelectLanguageModalComponent)
    this.selLangModal.onClose.subscribe((lang: any) => {
      if (lang != this.langService.getLangActive()) {
        this.langService.setLanguage(lang)
        window.location.reload();
      }
    });
  }

  getCustomerName() {
    let name = 'NO-NAME'
    if (this.sessionObject.customer) {
      name = this.sessionObject.customer.email
      if (this.sessionObject.customer.personal_data) {
        name = this.sessionObject.customer.personal_data.first_name
        if (this.sessionObject.customer.personal_data.last_name) {
          name = name + ' ' + this.sessionObject.customer.personal_data.last_name
        }
      }
    }
    return name
  }

  get isProductSite() {
    return environment.ownerId == 0
  }

  get appName(){
    return environment.appName
  }

  loadLogo() {
    this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.DF_LOGO)
    if (Commons.validField(Commons.getOwner())) {
      this.storageService.getCompanyLogoByOwnerId(Commons.getOwner().id).subscribe(
        {
          next: async (v) => {
            this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(v))
          },
          error: (e) => {
            this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.DF_LOGO)
          },
          complete: () => { }
        }
      )
    }
  }

  closeNavbar(): void {
    // Encuentra el elemento del menú desplegable y ciérralo
    const navbar = this.mainNavbar.nativeElement;
    if (navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  }
  
  /* BEGIN | CUSTOM FUNCTIONS */
  /* END | CUSTOM FUNCTIONS */
}
