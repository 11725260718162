import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OpenaiService {
    assistant_id: string = environment.assistant_id
    url: string = environment.servicesBackendEndpoint

    constructor(private http: HttpClient) { }

    sendMessage(message: string, thread_id: string | null) {
        let url = `${this.url}/assistant/send?assistant_id=${this.assistant_id}`;
        if (thread_id) {
          url += `&thread_id=${thread_id}`;
        }
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          })
        };
        const request = {
          message: message
        };
        return this.http.post<any>(url, request, options);
      }

    getMessages(thread_id: string) {
        const url = `${this.url}/assistant/get?thread_id=${thread_id}`;
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        return this.http.get<any>(url, options)
    }

}
