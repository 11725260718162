export const environment = {
  baseUrl: 'https://dev.softdirex.com',
  // Used for redirextion from client sites
  productSite: 'https://publinex-dev.softdirex.com',
  checkservices: 'https://services-dev.softdirex.com',
  enableWebmailSiteLink: true,
  // https://mail.google.com/
  webmailSite: 'https://mail.google.com/',
  production: true,
  appVersion: '1.1.1',
  localeKey: 'NIKqPYrGejeDFCcRC0Y82V',
  appName: 'Publinex',
  appDescription: 'Manage publications and content with themes associated with your organization',
  appTags: 'posts,publications,notices,blog,organizations,corporative blogs,rrss,newsletter,news,noticias,blogs corporativos,publicaciones,empresas,sitios web,website,web',
  footerInfo: 'Softdirex - Innovate your digital tools',
  officeLocation: 'La Serena - Chile',
  contactEmail: 'sales@softdirex.com',
  contactPhone: '+569 9867 2957',
  orgName: 'Softdirex',
  // Direccion web de softdirex
  providerWeb: 'https://softdirex.com/',
  // OWNER_ID Asociado al producto, si está en 0 carga datos de softdirex
  ownerId: 1,
  productId: 1,
  // BEGIN - Owner config default
  dfConfigCompanyName: 'Softdirex',
  dfConfigSlogan: 'Moderniza tus herramientas digitales',
  dfConfigAbout: 'Somos una empresa que está en crecimiento, estamos encausados en dar una atención grata y practica a los clientes, nuestro esmero es complacer a nuestros clientes en sus requerimientos tecnológicos para seguir creciendo. Contamos con un área de investigación para crear nuevas soluciones de vanguardia.<br><br>Contamos con un equipo de programadores profesionales plenamente capacitados para dar soluciones efectivas a las nuevas necesidades y aportar con ello al crecimiento tecnológico de su empresa.',
  dfConfigMission: 'Nuestra misión es promover y brindar a las pequeñas y medianas empresas mejores herramientas específicas de administración de software que las ayudarán a competir de manera más efectiva en el mercado, entregando las mejores herramientas digitales para hacer públicos sus productos o servicios al instante, agilizando la forma en que comparte su información con el mundo según los estándares digitales actuales junto con promover y proveer a las pequeñas y medianas empresas de mejores herramientas administrativas de software específicas que ayudarán a competir más eficazmente en el mercado.',
  dfConfigVision: 'Todos debemos tener acceso a sistemas de información modernos, sin importar el tamaño de nuestros proyectos, cualquier implementación efectiva de tecnología contribuirá a un mejor desarrollo y a la larga traerá grandes ganancias.',
  dfConfigContactPhone: '+569 9867 2957',
  dfConfigContactMail: 'info@softdirex.com',
  dfConfigAddress: '',
  dfConfigCity: 'La Serena',
  dfConfigCountry: 'Chile',
  dfConfigTermsFilename: 'terms.json',
  dfConfigLang: 'es',
  dfConfigDaysValidQuote: 15,
  dfConfigDefaultNote: '',
  dfConfigTaxPercentage: 0,
  // END - Owner config default
  productBackendEndpoint: 'https://api-publinex-dev.softdirex.com/v1',
  coreFrontendEndpoint: 'https://escritorio-dev.softdirex.com/',
  pwdRegex: '^[\x20-\x7E]{8,30}$',
  rutRegex: '\\b[0-9|.]{1,10}\\-[K|k|0-9]',
  passportRegex: '[a-zA-Z0-9-]{6,100}',
  dniRegex: '[a-zA-Z0-9-]{6,100}',
  documentDataRegex: '[a-zA-Z0-9-]{6,100}',
  namesRegex: '[a-zA-Z\u00C0-\u00ff-\' ]{2,90}',
  phonesRegex: '[0-9+() -]{8,35}',
  addressRegex: '[a-zA-Z\u00C0-\u00ff-.@ 0-9]{2,90}',
  rrssRegex: '[a-zA-Z-/:.#@_0-9%=?&]{1,90}',
  obsRegex: '[a-zA-Z/%\u00C0-\u00ff-.,&\'\n": 0-9]{2,1000}',
  aboutRegex: '[a-zA-Z/¡!()@=?¿%\u00C0-\u00ff&-.,;\'\n": 0-9]{2,5000}',
  sessionTimeMins: 55,
  recaptcha: {
    siteKey: '6LcSKvEkAAAAAFfhUa9wcIhCFAd_P_yZFwN6lRNj',
  },
  withBackgroundBody: true,
  withFooterLogo: true,
  withNavBarLogo: true,
  map: {
    gmapsApiKey: "AIzaSyAvyilehl7RQjrDiHEoTRNI1I3JoLg2KKY",
    lat: -29.958324,
    lng: -71.266277,
    enabled: false
  },
  // Social: dejar en blanco si no existe informacion
  social: {
    youtube: 'https://www.youtube.com/@softdirexsolucionesinforma2851',
    instagram: 'https://www.instagram.com/softdirex_/',
    x: 'https://twitter.com/softdirex',
    facebook: 'https://web.facebook.com/Softdirex/',
    linkedin: 'www.linkedin.com/company/softdirex'
  },
  googleClientId: '299928096210-g4r6tonbi1g9a47f798cum1muuo32e0q.apps.googleusercontent.com',
  assistant_id: 'asst_qT5656Als62q3zwR9PABlBNn',
  servicesBackendEndpoint: 'http://127.0.0.1:8000/gptservice'
};
